import React from 'react';

import './App.css';
import { ZoomMtg } from '@zoom/meetingsdk';

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
const urlParams = new URLSearchParams(window.location.search);
const user = urlParams.get("user");

function App() {
  const token = user;
  const payload = token.split('.')[1];
  const decodedPayload = JSON.parse(window.atob(payload));
  var authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT
  var sdkKey = process.env.REACT_APP_SDK_KEY
  var userEmail = process.env.REACT_APP_USER_EMAIL
  var registrantToken = ''

  let leaveUrl;
  if (decodedPayload.role === 0) {
    leaveUrl = process.env.REACT_APP_LEAVE_URL_ACCOUNT;
  } else if (decodedPayload.role === 1) {
    leaveUrl = process.env.REACT_APP_LEAVE_URL_COMPANY;
  }

  const meetingNum = parseInt(decodedPayload.meetingNumber, 10);
  function getSignature(e) {
    e.preventDefault();

    fetch(authEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNum,
        role: decodedPayload.role
      })
    }).then(res => res.json())
      .then(response => {
        startMeeting(response.signature)
      }).catch(error => {
        console.error(error)
      })
  }

  function startMeeting(signature) {
    const meetingNum = parseInt(decodedPayload.meetingNumber, 10);
    console.log("decodedPayloadMeei", parseInt(decodedPayload.meetingNumber, 10))
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      patchJsMedia: true,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNum,
          passWord: decodedPayload.passWord,
          userName: decodedPayload.userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: decodedPayload.zak,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App">
      <main style={{
        height: "100vh", width: "100%", background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
        display: "flex", justifyContent: "center", alignItems: "center"
      }}>
        {decodedPayload ? (
          <div>
            <img src="heplin.png" alt="zoom" />
            <p style={{ color: "white", fontSize: "35px" }}>Welcome,{" " + decodedPayload.userName} !</p>
            <button style={{ background: "white", color: "#36BCFF", fontWeight: 700, boxShadow: "rgb(0 0 0 / 20%) 0px 2px 8px 0px" }} onClick={getSignature}>Join Meeting</button>
          </div>

        ) :
          (
            <p>Invalid URl</p>
          )}

      </main>
    </div>
  );
}

export default App;
